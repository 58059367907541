.navContainer {
    width: 100%;
    grid-row: 3 ;
    display: flex;
    justify-content: space-between;
}

p {
    font-family: Andalemo, serif;
    font-size: 17px;
    color: white;
}

.border {
    margin-top: 4px;
    border-bottom: 0.5rem solid;
    border-radius: 20px;
}