.sun {
    grid-row: 1/3;
    height: 30vh;
    width: 30vh;
    background-color: #E4DF9E;
    border-radius: 50%;
    box-shadow:  0 0 4vh #ffffff;

}

.sun>p {
    color: white;
    font-family: Andalemo, serif;
    font-size: 50px;
    padding: 8vh 7vh 9vh 5vh;
}